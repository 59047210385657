import React, { Fragment, useRef } from "react";
import PlotlyUI from "./PlotlyUI";
import SettingsUI from "./SettingsUI";

const MainUI = (props) => {

    const plotlyUIRef = useRef();
    const plotlyUICallback = () => {
        plotlyUIRef.current.callRefresh();
    }

    return (
        <Fragment>
            <SettingsUI callback={() => plotlyUICallback()}/>
            <PlotlyUI ref={plotlyUIRef} />
        </Fragment>);
};

export default MainUI;