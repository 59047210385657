import React from "react"
import { Row } from "react-bootstrap"

const YahooSuggestionsRow = (props) => {

    let symbol_dict = props.symbol_dict
    let row_type = props.row_type
    
    const plus_btn_clicked = () => {
        console.log("Add Stuff to localStorage...")
    }

    return (
        <Row className={ "px-2 yahoo_results_row " + (row_type === "even" ? "yahoo_results_row_container_even" : "yahoo_results_row_container_odd") }>
            <div className="p-2 m-0 yahoo_results_plus_btn_col">
                <div onClick={ () => plus_btn_clicked() } className="m-0 yahoo_results_plus_btn_div">+</div>
            </div>
            <p className="p-2 m-0 yahoo_results_symbol_col">{symbol_dict["symbol"]}</p>
            <p className="p-2 m-0 yahoo_results_exchange_col">({symbol_dict["exchDisp"]})</p>
            <p className="p-2 m-0 yahoo_results_name_col">{symbol_dict["shortname"]}</p>
        </Row>
    )
}

export default YahooSuggestionsRow