import { lpad } from "./utils";

export default class DataService {

    static instance = DataService.instance || new DataService();

    constructor() {
        this.pricesArray = [];
        this.linearTrendArray = [];
        this.currentAssetName = "";
        this.currentSymbol = "";
        this.timeframeStartDate = null;
        this.timeframeStartDate = null;
    }


    getYoyRoi = () => {
        let cumulativeYoyRois = 0;
        let yoyRoiCnt = 0;
        for(let idx = this.linearTrendArray.length-1; 0 <= idx; idx--) {
            let ref_date = new Date(this.linearTrendArray[idx][0]);
            let start_idx = -1;
            let end_idx = -1;
            for(let jdx = this.linearTrendArray.length-1; 0 <= jdx; jdx--) {
                let iterDate = new Date(this.linearTrendArray[jdx][0]);
                if( ref_date.getTime() <= iterDate.getTime()) {
                    end_idx = jdx;
                    jdx -= 360; //Skip day inbetween (dramatic speed increase!)
                }
                if( ref_date.getTime() - (1000*86400*365) <= iterDate.getTime() ) 
                    start_idx = jdx;
                else
                    break; // once we have a start_idx we are done. So break the inner loop (dramatic speed increase)
                
            }
            
            if( 0 < start_idx && 0 < end_idx) {
                let d1 = new Date(this.linearTrendArray[start_idx][0]);
                let d2 = new Date(this.linearTrendArray[end_idx][0]);
                let n_days = Math.ceil(Math.abs(d1 - d2)/(1000*24*60*60));
                if(n_days === 365) {
                    cumulativeYoyRois = cumulativeYoyRois + parseFloat(this.linearTrendArray[end_idx][1])/parseFloat(this.linearTrendArray[start_idx][1])
                    yoyRoiCnt++;
                }

                if(yoyRoiCnt === 10) //We just need a few. Leave others (dramatic speed increase)
                    break;
            }
        }

        return parseFloat(cumulativeYoyRois)/parseFloat(yoyRoiCnt);
    }


    offsetLinearTrendArray = (percentage, direction) => {
        percentage = direction === "up" ? 1.0 + Math.abs(percentage)/100.0 : 1.0 - Math.abs(percentage)/100.0;
        percentage = Math.max(0, Math.min(percentage, 2.0));
        for(let index = 0; index < this.linearTrendArray.length; ++index) {
            this.linearTrendArray[index][1] = percentage*this.linearTrendArray[index][1];
        }
    }


    getMinPrice = () => {
        let minValue = 1e100;
        for(let i = 0; i < this.pricesArray.length; ++i) {
            if( this.pricesArray[i][1] <= minValue )
                minValue = this.pricesArray[i][1]
        }
        return minValue;
    }


    getMaxPrice = () => {
        let maxValue = 1;
        for(let i = 0; i < this.pricesArray.length; ++i) {
            if( maxValue <= this.pricesArray[i][1] )
            maxValue = this.pricesArray[i][1]
        }
        return maxValue;
    }


    getPriceDataForPeriod1 = () =>  {
        if(this.pricesArray.length === 0) 
            return []
        let period1PricesArray = [];
        for(let index = 0; index < this.pricesArray.length; index++) {
            let iterDate = new Date(Date.parse(this.pricesArray[index][0]));
            if(iterDate.getTime() < this.timeframeStartDate.getTime()) {
                let priceValue = this.pricesArray[index][1];
                period1PricesArray.push([iterDate, priceValue])
            }
        }
        return period1PricesArray;
    }


    getPriceDataForPeriod2 = () => {
        if(this.pricesArray.length === 0) 
            return []
        let period2PricesArray = [];
        for(let index = 0; index < this.pricesArray.length; index++) {
            let iterDate = new Date(Date.parse(this.pricesArray[index][0]));
            if(this.timeframeStartDate.getTime() <= iterDate.getTime() && iterDate.getTime() <= this.timeframeEndDate.getTime()) {
                let priceValue = this.pricesArray[index][1];
                period2PricesArray.push([iterDate, priceValue])
            }
        }
        return period2PricesArray;
    }


    getPriceDataForPeriod3 = () => {
        if(this.pricesArray.length === 0) 
            return []
        let period3PricesArray = [];
        for(let index = 0; index < this.pricesArray.length; index++) {
            let iterDate = new Date(Date.parse(this.pricesArray[index][0]));
            if(this.timeframeEndDate.getTime() < iterDate.getTime()) {
                let priceValue = this.pricesArray[index][1];
                period3PricesArray.push([iterDate, priceValue])
            }
        }
        return period3PricesArray;
    }



    getDatesForPlotly = (rawInputArray) => {
        let datesArray = [];
        for(let i = 0; i < rawInputArray.length; ++i) {
            let dt = new Date(Date.parse(rawInputArray[i][0]));
            //'2013-10-04'
            let transformedDateTime = dt.getFullYear() + "-" + lpad((1+dt.getMonth()),2) + "-" + lpad((1+dt.getDate()),2);
            datesArray.push( transformedDateTime );
        }
        return datesArray;
    }


    getValuesForPlotly = (rawInputArray) => {
        let valuesArray = [];
        for(let i = 0; i < rawInputArray.length; ++i) {
            valuesArray.push(rawInputArray[i][1]);
        }
        return valuesArray;
    }

}