import "../../css/styles.css"
import React from "react"
import YahooIsinLookup from "./Yahoo-ISIN-Lookup"

const YahooAddSymbol = (props) => {

    return (
        <div className="yahoo_add_symbol_floating_container">
            <YahooIsinLookup />
        </div>
    )
}

export default YahooAddSymbol