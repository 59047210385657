import Plot from 'react-plotly.js';

import React, { Fragment, useImperativeHandle, useState } from "react";
import DataService from '../logic/DataService';
import "../css/PlotlyUI.css";

const PlotlyUI = React.forwardRef((props, ref) => {

    const colorPlotlyBlue = "#1F77B4";
    const colorPlotlyGreen = "#29FF2D";

    let [refreshMe, setRefreshMe] = useState(0);
    const refresh = () => {
        setRefreshMe(refreshMe+1);
    }
 
    useImperativeHandle(ref, () => {
        return { callRefresh : refresh };
    })
 
    let period1PricesArray = DataService.instance.getPriceDataForPeriod1();
    let period2PricesArray = DataService.instance.getPriceDataForPeriod2();
    let period3PricesArray = DataService.instance.getPriceDataForPeriod3();

    const data = [
        {
            x: DataService.instance.getDatesForPlotly(period1PricesArray),
            y: DataService.instance.getValuesForPlotly(period1PricesArray),
            type: 'scatter',
            marker: { color : colorPlotlyBlue },
        },
        {
            x: DataService.instance.getDatesForPlotly(period2PricesArray),
            y: DataService.instance.getValuesForPlotly(period2PricesArray),
            type: 'scatter',
            marker: { color : colorPlotlyGreen },
        },
        {
            x: DataService.instance.getDatesForPlotly(period3PricesArray),
            y: DataService.instance.getValuesForPlotly(period3PricesArray),
            type: 'scatter',
            marker: { color : colorPlotlyBlue },
        },
        {
            x: DataService.instance.getDatesForPlotly(DataService.instance.linearTrendArray),
            y: DataService.instance.getValuesForPlotly(DataService.instance.linearTrendArray),
            type: 'scatter'
        },
    ]

    const layout = {  
        width: window.innerWidth, 
        height: (8/16*window.innerWidth), 
        title: "Log10-Chart for " + DataService.instance.currentAssetName + " ( Ticker-Symbol: " + DataService.instance.currentSymbol + ") - Orange line: Cross-validated linear regression line (future extrapolated)",
        showlegend: false,
        yaxis: {
            type: 'log',
            range: [Math.log10(DataService.instance.getMinPrice()), Math.log10(DataService.instance.getMaxPrice())]
        }, 
    };


    return (<Fragment>
        { 0 < DataService.instance.pricesArray.length &&<p className="plotly-ui-yoyroi-p">{DataService.instance.currentAssetName} : Extremely Long Term YoY Roi: { (100*(DataService.instance.getYoyRoi()-1)).toFixed(4) }%</p>}
        { 0 < DataService.instance.pricesArray.length && <Plot data={data} layout={layout} /> }
    </Fragment>)
});


export default PlotlyUI;