/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useState, useRef}  from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import useHttp from '../logic/useHttp';
import DataService from '../logic/DataService';
import "../css/SettingsUI.css";
import YahooAddSymbol from './yahoo/Yahoo-AddSymbol';

const SettingsUI = (props) => {

    // Show/Hide controls for adjusting the offset of the regression line
    let modifyRegLineOffsetAmountInput = useRef();
    let [regressionLineOffsetControlsEnabled, setRegressionLineOffsetControlsEnabled] = useState(false);

    let {isLoading, error, sendRequest} = useHttp();
    let [startDate, setStartDate] = useState(null);
    let [endDate, setEndDate] = useState(null);
    let [assetName, setAssetName] = useState("Asset/Index-Auswahl");
    let [symbol, setSymbol] = useState(null);
    let [show_add_symbols_popup, set_show_add_symbols_popup] = useState(false)
    
    
    let assets = {
        "JPM Global Equity Multi-Factor" : {
            "symbol" : "JPGL.DE",
            "name" : "JPM Global Equity Multi-Factor",
            "default-start" : new Date(1985, 0, 1),
            "default-end" : new Date()
        },
        "XTrackers MSCI World Minimum Volatility" : {
            "symbol" : "XDEB.DE",
            "name" : "XTrackers MSCI World Minimum Volatility",
            "default-start" : new Date(1985, 0, 1),
            "default-end" : new Date()
        },
        "SPDR MSCI World Small Cap" : {
            "symbol" : "WDSC.L",
            "name" : "SPDR MSCI World Small Cap",
            "default-start" : new Date(1985, 0, 1),
            "default-end" : new Date()
        },
        "Dax-40" : {
            "symbol" : "^GDAXI",
            "name" : "Dax-40",
            "default-start" : new Date(1985, 0, 1),
            "default-end" : new Date()
        },
        "Hang Seng" : {
            "symbol" : "^HSI",
            "name" : "Hang Seng",
            "default-start" : new Date(1985, 0, 1),
            "default-end" : new Date()
        },
        "Nasdaq 100" : {
            "symbol" : "^NDX",
            "name" : "Nasdaq 100",
            "default-start" : new Date(1985, 0, 1),
            "default-end" : new Date()
        },
        "Nasdaq Composite" : {
            "symbol" : "^IXIC",
            "name" : "Nasdaq Composite",
            "default-start" : new Date(1985, 0, 1),
            "default-end" : new Date()
        },
        "S&P 500" : {
            "symbol" : "^GSPC",
            "name" : "S&P 500",
            "default-start" : new Date(1955, 0, 1),
            "default-end" : new Date()
        },
        "S&P 500 (dividends reinvested)" : {
            "symbol" : "^SP500TR",
            "name" : "S&P 500 (dividends reinvested)",
            "default-start" : new Date(1955, 0, 1),
            "default-end" : new Date()
        },
        "MDax" : {
            "symbol" : "^MDAXI",
            "name" : "MDax",
            "default-start" : new Date(1985, 0, 1),
            "default-end" : new Date()
        },
        "TecDax" : {
            "symbol" : "^TECDAX",
            "name" : "TecDax",
            "default-start" : new Date(1985, 0, 1),
            "default-end" : new Date()
        },
        "Euro Stoxx 50" : {
            "symbol" : "^STOXX50E",
            "name" : "Euro Stoxx 50",
            "default-start" : new Date(1985, 0, 1),
            "default-end" : new Date()
        },
    };

    const chooseAsset = (name, symbol) => {
        setAssetName(name);
        setSymbol(symbol);
        setStartDate(assets[name]["default-start"]);
        setEndDate(assets[name]["default-end"]);
    }


    const onAnalyzeClicked = () => {
        const requestConfig = {
            url : window.baseURL + "/data_service?symbol=" + symbol + "&start_date=" + startDate.toISOString().slice(0, 10) + "&end_date=" + endDate.toISOString().slice(0, 10),
            onSuccessFunction : (responseJson) => {
                if(responseJson["dates-and-prices"] != null) {
                    DataService.instance.pricesArray = responseJson["dates-and-prices"];
                    DataService.instance.linearTrendArray = responseJson["linear-trend"];
                    DataService.instance.currentAssetName = assetName;
                    DataService.instance.currentSymbol = symbol;
                    DataService.instance.timeframeStartDate = startDate;
                    DataService.instance.timeframeEndDate = endDate;
                    props.callback();
                    setRegressionLineOffsetControlsEnabled(true);
                } else {
                    console.log("Error key 'dates-and-prices' not found in json");
                }
            }
        };
        sendRequest(requestConfig);
    }


    const moveRegressionLine = (moveUp) => {
        let offsetAmount = modifyRegLineOffsetAmountInput.current.value;
        if( offsetAmount.match(/[0-9]+,{0,1}[0-9]*/) != null) {
            offsetAmount = parseFloat(offsetAmount.match(/[0-9]+,{0,1}[0-9]*/)[0].replace(",","."))
            let direction = moveUp ? "up" : "down";
            DataService.instance.offsetLinearTrendArray(offsetAmount, direction);
            props.callback();
        } else {
            alert("Bitte eine gültige Zahl eingeben. ZB. 16,52");
        }
    }


    return (
        <Fragment>
            <div className="settings-ui-container">
                <div>
                    <Button variant="secondary" style={ { "borderRadius" : "20px"} } onClick={() => set_show_add_symbols_popup(!show_add_symbols_popup) }>+</Button>
                </div>
                { show_add_symbols_popup && <YahooAddSymbol/> }
                <div>
                <Dropdown>
                    <Dropdown.Toggle className="settings-ui-dropdown-toggle" variant="secondary" id="dropdown-basic">
                        {assetName}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={ () => chooseAsset(assets["Dax-40"].name, assets["Dax-40"].symbol) }>{assets["Dax-40"].name + " (" + assets["Dax-40"].symbol + ")"}</Dropdown.Item>
                        <Dropdown.Item onClick={ () => chooseAsset(assets["Nasdaq 100"].name, assets["Nasdaq 100"].symbol) }>{assets["Nasdaq 100"].name + " (" + assets["Nasdaq 100"].symbol + ")"}</Dropdown.Item>
                        <Dropdown.Item onClick={ () => chooseAsset(assets["Nasdaq Composite"].name, assets["Nasdaq Composite"].symbol) }>{assets["Nasdaq Composite"].name + " (" + assets["Nasdaq Composite"].symbol + ")"}</Dropdown.Item>
                        <Dropdown.Item onClick={ () => chooseAsset(assets["S&P 500"].name, assets["S&P 500"].symbol) }>{assets["S&P 500"].name + " (" + assets["S&P 500"].symbol + ")"}</Dropdown.Item>
                        <Dropdown.Item onClick={ () => chooseAsset(assets["S&P 500 (dividends reinvested)"].name, assets["S&P 500 (dividends reinvested)"].symbol) }>{assets["S&P 500 (dividends reinvested)"].name + " (" + assets["S&P 500 (dividends reinvested)"].symbol + ")"}</Dropdown.Item>
                        <Dropdown.Item onClick={ () => chooseAsset(assets["Euro Stoxx 50"].name, assets["Euro Stoxx 50"].symbol) }>{assets["Euro Stoxx 50"].name + " (" + assets["Euro Stoxx 50"].symbol + ")"}</Dropdown.Item>
                        <Dropdown.Item onClick={ () => chooseAsset(assets["Hang Seng"].name, assets["Hang Seng"].symbol) }>{assets["Hang Seng"].name + " (" + assets["Hang Seng"].symbol + ")"}</Dropdown.Item>
                        <Dropdown.Item onClick={ () => chooseAsset(assets["MDax"].name, assets["MDax"].symbol) }>{assets["MDax"].name + " (" + assets["MDax"].symbol + ")"}</Dropdown.Item>
                        <Dropdown.Item onClick={ () => chooseAsset(assets["TecDax"].name, assets["TecDax"].symbol) }>{assets["TecDax"].name + " (" + assets["TecDax"].symbol + ")"}</Dropdown.Item>
                        <Dropdown.Item onClick={ () => chooseAsset(assets["SPDR MSCI World Small Cap"].name, assets["SPDR MSCI World Small Cap"].symbol) }>{assets["SPDR MSCI World Small Cap"].name + " (" + assets["SPDR MSCI World Small Cap"].symbol + ")"}</Dropdown.Item>
                        <Dropdown.Item onClick={ () => chooseAsset(assets["XTrackers MSCI World Minimum Volatility"].name, assets["XTrackers MSCI World Minimum Volatility"].symbol) }>{assets["XTrackers MSCI World Minimum Volatility"].name + " (" + assets["XTrackers MSCI World Minimum Volatility"].symbol + ")"}</Dropdown.Item>
                        <Dropdown.Item onClick={ () => chooseAsset(assets["JPM Global Equity Multi-Factor"].name, assets["JPM Global Equity Multi-Factor"].symbol) }>{assets["JPM Global Equity Multi-Factor"].name + " (" + assets["JPM Global Equity Multi-Factor"].symbol + ")"}</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                </div>
                <div>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label={"Start-Datum"}
                            value={startDate}
                            onChange={(newValue) => { setStartDate(newValue); }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </div>
                <div>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label={"End-Datum"}
                            value={endDate}
                            onChange={(newValue) => { setEndDate(newValue); }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </div>

                <div>
                {!isLoading && <Button variant="success" onClick={ () => onAnalyzeClicked() }>Analysieren</Button>}
                {isLoading &&  <div className="spinner-div-width"><div className="spinner-border text-success" role="status" /></div> }
                </div>

                {regressionLineOffsetControlsEnabled && 
                <div className="regression-offset-controls-container">
                    <div>
                        <input type="number" step="any" ref={modifyRegLineOffsetAmountInput} className="regression-offset-amount-input"/>%
                    </div>
                    <div>
                        <div className="regression-control-btn-container">
                            <a className="regression-control-btn" href="#" onClick={ () => moveRegressionLine(true) }>△</a>
                        </div>
                        <div className="regression-control-btn-container">
                            <a className="regression-control-btn" href="#" onClick={ () => moveRegressionLine(false) }>▽</a>
                        </div>
                    </div>
                </div>}
            </div>
            { error != null && 
                <div className="settings-ui-error-div">
                    <p className="settings-ui-error-p">Fehler bei der Kommunikation mit dem Backend: {error}</p>
                </div>
            }   
        </Fragment>
    );
};

export default SettingsUI;