import './App.css';
import MainUI from './ui/MainUI';
import React from "react"

function App() {

  window.baseURL = document.getElementById("url").innerHTML;

  return (<MainUI/>);
}

export default App;
