import React, { Fragment, useRef, useState } from "react"
import useHttp from '../../logic/useHttp';
import { Button, Container, Form, Spinner, Stack } from "react-bootstrap";
import YahooSuggestionsRow from "./Yahoo-Suggestion-Row";

const YahooIsinLookup = () => {

    let { isLoading, sendRequest } = useHttp();
    let [error_msg, set_error_msg] = useState("")
    let [symbol_dicts, set_symbol_dicts] = useState([])
    let [show_magnifier, set_show_magnifier] = useState(true)
    let isin_input_ref = useRef();

    /* Beispiel Antwort
    [
        {
            "exchange": "STU",
            "shortname": "Invesco MDAX UCITS ETF EUR Acc",
            "quoteType": "MUTUALFUND",
            "symbol": "IE00BHJYDV33.SG",
            "index": "quotes",
            "score": 20002.0,
            "typeDisp": "Fund",
            "exchDisp": "Stuttgart",
            "isYahooFinance": true
        },
        {
            "exchange": "GER",
            "shortname": "INVESCOM2 MDAX A",
            "quoteType": "ETF",
            "symbol": "DEAM.DE",
            "index": "quotes",
            "score": 20001.0,
            "typeDisp": "ETF",
            "longname": "Invesco Markets II plc - Invesco MDAX UCITS ETF",
            "exchDisp": "XETRA",
            "isYahooFinance": true
        }
    ]
    */

    const on_focus = () => {
        set_show_magnifier(true)
    }

    const on_blur = () => {
        if( 0 < isin_input_ref.current.value.length)
            set_show_magnifier(false)
    }

    const clearHandler = () => {
        set_symbol_dicts([])
        isin_input_ref.current.value = ""
        set_show_magnifier(true)
    }

    const submitHandler = (event) => {
        event.preventDefault();
        let isin = isin_input_ref.current.value;
        if( 0 < isin.length ) {
            const requestConfig = {
                url : window.baseURL + "data_service",
                method : "POST",
                headers : { "Content-Type" : "application/json" },
                body: { "service_type": "yahoo_finance_lookup", 
                        "isin": isin },
                onSuccessFunction : (responseJson) => {
                    if(responseJson.result === "success") {
                        set_symbol_dicts(responseJson.payload)
                    } else 
                        set_error_msg("Http Error")
                }
            };
            sendRequest(requestConfig);
        }
    }

    return (
        <Container className="p-0">
            <Form onSubmit={ submitHandler }>
                <Stack direction="horizontal" gap={2}>
                    <Form.Control ref={isin_input_ref} type="text" onFocus={ () => on_focus() } onBlur={ () => on_blur() } placeholder="Enter ISIN/Company name" />
                    { (!isLoading && show_magnifier ) && <Button type="submit" variant="secondary" onClick={ submitHandler }><span role="img" aria-label="Search">🔍</span></Button> }
                    { (!isLoading && !show_magnifier  ) && <Button variant="secondary" onClick={ clearHandler }><span role="img" aria-label="Cancel">❌</span></Button> }
                    { isLoading &&  <Spinner animation="border" variant="spinner-border text-warning" />}
                </Stack>
            </Form>
            { error_msg.length > 0 && <p className="mt-2 px-4 py-2 error_p">{ error_msg }</p> }

            { 0 < symbol_dicts.length && 
                <Fragment>
                    <h4 className="mt-2">Gefundene Symbole für <span className="yahoo_results_isin_span">{isin_input_ref.current.value}</span></h4>
                    <Container className="mt-2 p-0 yahoo_isin_lookup_results_container_div">
                        { symbol_dicts.map( (symbol_dict, idx) => { return <YahooSuggestionsRow key={idx} isin={isin_input_ref.current.value} row_type={ idx % 2 === 0 ? "even" : "odd"} symbol_dict={symbol_dict}/>} ) }
                    </Container>
                </Fragment>
            }
        </Container>
    )
}

export default YahooIsinLookup